import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App, { unitTypeI } from "./App";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import { BrowserRouter, Route } from "react-router-dom";
import { DefaultDataContextProvider } from "./hooks/useDefaultData";
import "./index.css";
import "./i18n";
import AppContextProvider from "./contexts/App.context";
import createCache from "@emotion/cache";
import {
  createTheme,
  ScopedCssBaseline,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { styleTags } from "./utils/style-loader";
import { StyleSheetManager } from "styled-components";
import ShadowDomContextProvider from "./contexts/ShadowDom.context";
import { CacheProvider } from "@emotion/react";

export const isProd = process.env.NODE_ENV === "production";

declare global {
  interface Window {
    availRoomBookingWidget: any;
  }
}

export interface propsConfigI {
  environment: string;
  loading?: boolean;
  baseLanguage?: string;
  shadowDom?: boolean;
}

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ` "Poppins","Roboto","-apple-system","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Poppins",
            "Roboto",
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Helvetica Neue",
            "Arial",
            "sans-serif",
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
          ].join(","),
        },
      },
    },
  },
};

class availRoomBookingWidget {
  constructor(propsConfig: {
    divId: string;
    environmentId: string;
    domainUrl: string;
    language: string;
    type: unitTypeI;
    vertical?: boolean;
    shadowDom?: boolean;
  }) {
    const {
      divId,
      environmentId,
      domainUrl,
      language,
      type,
      vertical,
      shadowDom,
    } = propsConfig;

    const containerEl = document.getElementById(divId);

    if (!containerEl) {
      throw new Error(`no container element with id ${divId} found`);
    }

    let emotionRoot;

    if (shadowDom) {
      const shadowContainer =
        containerEl.shadowRoot ?? containerEl.attachShadow({ mode: "open" });
      emotionRoot = document.createElement("style");

      const shadowRootEl = document.createElement("div");

      const portalRootEl: HTMLDivElement =
        document.querySelector("#availroom-portal-root") ??
        document.createElement("div");

      const portalEmotionRoot = document.createElement("style");
      const portalSadowContainer =
        portalRootEl.shadowRoot ?? portalRootEl.attachShadow({ mode: "open" });
      document.body.appendChild(portalRootEl);

      const portalRootContainerEl = document.createElement("div");
      portalRootContainerEl.setAttribute(
        "id",
        "availroom-portal-root-container-el"
      );
      const portalStyledComponentsContainerEl = document.createElement("div");

      const styledComponentsContainerEl = document.createElement("div");

      if (isProd) {
        const styleLink = document.createElement("link");
        styleLink.setAttribute(
          "href",
          isProd
            ? `${process.env.REACT_APP_HOSTNAME}/static/css/main.css`
            : "/static/css/main.css"
        );
        styleLink.setAttribute("rel", "stylesheet");
        styleLink.setAttribute("type", "text/css");
        shadowContainer.insertBefore(
          styleLink.cloneNode(true),
          shadowContainer.firstChild
        );
        portalSadowContainer.insertBefore(
          styleLink.cloneNode(true),
          portalSadowContainer.firstChild
        );
      }

      containerEl.setAttribute("data-shadowed", "style-isolated-shadowed");
      portalRootEl.setAttribute("data-shadowed", "style-isolated-shadowed");

      shadowContainer.appendChild(emotionRoot);
      shadowContainer.appendChild(styledComponentsContainerEl);
      shadowContainer.appendChild(shadowRootEl);

      portalSadowContainer.appendChild(portalRootContainerEl);
      portalSadowContainer.appendChild(portalEmotionRoot);
      portalSadowContainer.appendChild(portalStyledComponentsContainerEl);

      // import fonts
      const fontLinkEl = document.createElement("link");
      fontLinkEl.setAttribute(
        "href",
        "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap"
      );
      fontLinkEl.setAttribute("rel", "stylesheet");
      fontLinkEl.setAttribute("type", "text/css");

      shadowContainer.appendChild(fontLinkEl);
      portalSadowContainer.appendChild(fontLinkEl);

      const cache = createCache({
        key: "css",
        prepend: true,
        container: emotionRoot,
      });

      const root = ReactDOM.createRoot(shadowRootEl);

      const muiTheme = createTheme({
        ...themeOptions,
        components: {
          ...themeOptions.components,
          MuiPopover: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiPopper: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiModal: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
        },
      });

      document.head.append(...styleTags);
      root.render(
        <React.StrictMode>
          <AppContextProvider environmentId={environmentId}>
            <CacheProvider value={cache}>
              <ThemeProvider theme={muiTheme}>
                <ScopedCssBaseline>
                  <StyleSheetManager target={styledComponentsContainerEl}>
                    <ShadowDomContextProvider
                      portalRootContainerEl={portalRootContainerEl}
                      portalRootEl={portalRootEl}
                      shadowRoot={shadowContainer}
                      portalShadowRoot={portalSadowContainer}
                    >
                      <Suspense
                        fallback={
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CustomSpinner />
                          </div>
                        }
                      >
                        <BrowserRouter>
                          <Route path="/">
                            <DefaultDataContextProvider
                              relativePath={domainUrl}
                              defaultLanguage={language}
                              type={type}
                              vertical={vertical}
                            >
                              <App
                                environment={environmentId}
                                baseLanguage={language}
                                shadowDom={shadowDom}
                              />
                            </DefaultDataContextProvider>
                          </Route>
                        </BrowserRouter>
                      </Suspense>
                    </ShadowDomContextProvider>
                  </StyleSheetManager>
                </ScopedCssBaseline>
              </ThemeProvider>
            </CacheProvider>
          </AppContextProvider>
        </React.StrictMode>
      );
    } else {
      document.head.append(...styleTags);
      const root = ReactDOM.createRoot(containerEl);
      const muiTheme = createTheme({ ...themeOptions });

      root.render(
        <React.StrictMode>
          <AppContextProvider environmentId={environmentId}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CustomSpinner />
                </div>
              }
            >
              <ThemeProvider theme={muiTheme}>
                <BrowserRouter>
                  <Route path="/">
                    <DefaultDataContextProvider
                      relativePath={domainUrl}
                      defaultLanguage={language}
                      type={type}
                      vertical={vertical}
                    >
                      <App
                        environment={environmentId}
                        baseLanguage={language}
                        shadowDom={shadowDom}
                      />
                    </DefaultDataContextProvider>
                  </Route>
                </BrowserRouter>
              </ThemeProvider>
            </Suspense>
          </AppContextProvider>
        </React.StrictMode>
      );
    }
  }
}

const renderApp = () => {
  new availRoomBookingWidget({
    divId: "availRoomBookingWidget",
    environmentId: "89D8EBAFBABC4D918891F7EFC8D90556",
    domainUrl: "http://bookingv2.availroom.com",
    language: "es",
    type: "multiunit",
    shadowDom: true,
    // vertical: true,s
  });
};

if (!isProd) {
  renderApp();
}

export default availRoomBookingWidget;
