import React, { createContext, ReactNode, useContext } from "react";

interface ContextProps {
  environmentId: string;
}

const AppContext = createContext<ContextProps>({} as ContextProps);

const AppContextProvider: React.FC<{
  children?: ReactNode;
  environmentId: string;
}> = ({ children, ...props }) => {
  return <AppContext.Provider value={props}>{children}</AppContext.Provider>;
};
export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
