import React, { useState } from "react";
import styles from "./AgeInput.module.scss";
import DropdownContainer from "../../../../../dropdownContainer/DropdownContainer";
import { useDefaultData } from "../../../../../../hooks/useDefaultData";
import { useTranslation } from "react-i18next";
import { Box, List, ListItemButton, Stack } from "@mui/material";

const agesUnder18 = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
];

interface propsI {
  onChange?: (value: number) => void;
  value?: number;
}

const AgeInput: React.FC<propsI> = ({ onChange, value }) => {
  // redux hooks
  const { borderColor } = useDefaultData();
  const inputEl = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleSelect = (event: React.MouseEvent, age: number) => {
    // setSelectedAge(age);
    event.preventDefault();
    event.stopPropagation();
    setOpenDropdown(false);
    if (onChange) onChange(age);
  };

  return (
    <div className={`${styles.specificityWrapper} ${styles.AgeInputContainer}`}>
      <div
        className={styles.AgeInput}
        style={{
          ...(borderColor && { borderColor: borderColor }),
        }}
        onClick={handleOpenDropdown}
        ref={inputEl}
      >
        <div className={styles.leftContainer}>
          <span className={styles.label}>{value ?? 0}</span>
          <span className={styles.label}>{t("yearsOld")}</span>
        </div>
        <div className={styles.rightContainer}>
          <div
            className={`${styles.arrowBtn} ${openDropdown && styles.opened}`}
          >
            <img
              src="https://booking.availroom.com/v2/assets/icons/dropdown.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <DropdownContainer
        open={openDropdown}
        onClose={() => setOpenDropdown(false)}
        anchorEl={inputEl.current}
        fullWidth
      >
        <List>
          {agesUnder18.map((age) => (
            <ListItemButton
              key={age}
              onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                handleSelect(event, age)
              }
            >
              {age}
            </ListItemButton>
          ))}
        </List>
      </DropdownContainer>
    </div>
  );
};

export default AgeInput;
