import { Popover } from "@mui/material";
import React, { ReactNode, Ref } from "react";

const DropdownContainer = ({
  children,
  open,
  onClose,
  anchorEl,
  fullWidth,
}: {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  anchorEl?: Element | null;
  fullWidth?: boolean;
}) => {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      open={open}
      onClose={onClose}
      {...(fullWidth && {
        slotProps: {
          paper: {
            sx: {
              width: anchorEl?.clientWidth,
            },
          },
        },
      })}
    >
      {children}
    </Popover>
  );
};

export default DropdownContainer;
