import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";
import styles from "./CustomButton.module.scss";
import colors from "../../styles/variables.module.scss";
import styled from "styled-components";
import { useDefaultData } from "../../hooks/useDefaultData";

const Button = styled.button`
  background-color: ${(props: any) =>
    `${props.backgroundColor || props.mainColor || colors.primaryBlue} `};
  color: ${(props: any) =>
    tinycolor(props.backgroundColor || props.mainColor).isDark()
      ? "#ffffff "
      : "#000000 "};
  border: ${(props: any) =>
    `${props.borderWith} ${props.borderStyle} ${props.borderColor} `};
  border-radius: ${(props: any) => `${props.borderRadius || "100rem"} `};
  &:hover {
    background-color: ${(props: any) =>
      `${tinycolor(
        props.backgroundColor || props.mainColor || colors.primaryBlue
      )
        .clone()
        .darken(5)} `};
  }
  & * {
    color: ${(props: any) =>
      tinycolor(props.backgroundColor || props.mainColor).isDark()
        ? "#ffffff "
        : "#000000 "};
  }
`;

interface propsI {
  children?: string;
  backgroundColor?: string;
  iconUrl?: string;
  borderWith?: string;
  borderStyle?: string;
  borderColor?: string;
  borderRadius?: string;
  primary?: boolean;
  noWrap?: boolean;
  noLabel?: boolean;
  icon?: ReactElement<any, any>;
  isLeft?: boolean;
  className?: any;
}

const CustomButton: React.FC<propsI> = (props) => {
  const {
    children,
    iconUrl,
    backgroundColor,
    borderWith,
    borderStyle,
    borderColor,
    borderRadius,
    primary,
    noWrap,
    noLabel,
    icon,
    isLeft,
    className,
  } = props;
  const { t } = useTranslation();

  const { color } = useDefaultData();

  return (
    <div className={`${styles.specificityWrapper} ${styles.container}`}>
      <Button
        backgroundColor={backgroundColor}
        mainColor={color}
        borderWith={borderWith}
        borderStyle={borderStyle}
        borderColor={borderColor}
        borderRadius={borderRadius}
        className={`${styles.customButton} ${primary ? styles.primary : ""} ${
          noWrap ? styles.noWrap : ""
        } ${noLabel ? styles.noLabel : ""} ${className} ${
          isLeft ? styles.isLeft : ""
        }`}
      >
        {isLeft ? (
          <>
            {!noLabel && <p>{children || (t("labels.search") as string)}</p>}
            {(iconUrl || icon) && (
              <div className={styles.icon}>
                {iconUrl && <img src={`${iconUrl}`} alt="" />}
                {icon && icon}
              </div>
            )}
          </>
        ) : (
          <>
            {(iconUrl || icon) && (
              <div className={styles.icon}>
                {iconUrl && <img src={`${iconUrl}`} alt="" />}
                {icon && icon}
              </div>
            )}

            {!noLabel && <p>{children || (t("labels.search") as string)}</p>}
          </>
        )}
      </Button>
    </div>
  );
};

export default CustomButton;
