import { createContext, ReactNode, useContext } from "react";
import { WidgetProps } from "..";

interface Props extends WidgetProps {
  children?: ReactNode;
}
interface ContextProps extends WidgetProps {}

const AppContext = createContext<ContextProps>({} as ContextProps);

const AppContextProvider = ({ children, ...props }: Props) => {
  return <AppContext.Provider value={props}>{children}</AppContext.Provider>;
};
export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
