import React, { useRef } from "react";
import styles from "./LocationDropdown.module.scss";
import colors from "../../../../styles/variables.module.scss";
import { useDefaultData, ZoneI } from "../../../../hooks/useDefaultData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import DropdownContainer from "../../../dropdownContainer/DropdownContainer";

const LocationDropdown: React.FC = () => {
  const {
    borderColor,
    ubicationFilterList,
    ubication,
    setUbication,
    vertical,
  } = useDefaultData();

  const inputEl = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [openDropdown, setOpenDropdown] = useState(false);

  const renderLists = (filterList: ZoneI[]) => {
    let list: Array<
      ZoneI & {
        type: "zone" | "hotel";
      }
    > = [];
    filterList.map((filter) => {
      if (!filter.hotelDataList?.length) {
        list.push({ ...filter, type: "zone" });
      } else {
        list.push({ ...filter, type: "zone" });
        filter.hotelDataList.map((hotelFilter) => {
          list.push({ ...hotelFilter, type: "hotel" });
        });
      }
    });
    return list;
  };

  const handleToggleDropdown = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  return (
    <div className={`${styles.specificityWrapper} ${styles.locationDropdown}`}>
      <div
        className={`${styles.locationDropdownBox} ${
          vertical ? `${styles.vertical}` : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          handleToggleDropdown();
        }}
        style={{ borderColor: borderColor || colors.borderLightgray }}
        ref={inputEl}
      >
        <div className={styles.icon}>
          <img
            src="https://booking.availroom.com/v2/assets/icons/search.svg"
            alt=""
          />
        </div>
        <div className={styles.container}>
          <div
            className={styles.label}
            style={{
              color: colors.textLightgray,
            }}
          >
            {`${t("selected_locations")}`}
          </div>
          <div className={styles.selectedValue}>{ubication?.name}</div>
        </div>
        <div
          className={`${styles.dropdownIcon} ${
            openDropdown && styles.dropdownIconOpen
          }`}
        >
          <img
            src="https://booking.availroom.com/v2/assets/icons/dropdown.svg"
            alt=""
          />
        </div>
      </div>
      <DropdownContainer
        anchorEl={inputEl.current}
        open={openDropdown}
        onClose={() => setOpenDropdown(false)}
        fullWidth
      >
        <List>
          {renderLists(ubicationFilterList).map((ubicationFilter) => (
            <ListItemButton
              key={ubicationFilter.id}
              onClick={() => {
                setUbication({
                  id: ubicationFilter.id,
                  name: ubicationFilter.name,
                });
                setOpenDropdown(false);
              }}
            >
              <ListItemText>{ubicationFilter.name}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </DropdownContainer>
    </div>
  );
};

export default LocationDropdown;
