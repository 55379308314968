import React, { useEffect } from "react";
import { useState } from "react";
import { isProd, propsConfigI } from ".";
import useQuery from "./hooks/useQuery";

import Widget from "./components/widget/Widget";
import { useDefaultData } from "./hooks/useDefaultData";
import "./App.css";
import CustomSpinner from "./components/customSpinner/CustomSpinner";
import i18next from "i18next";

import styles from "./App.module.scss";

import useLanguage from "./hooks/useLanguage";
import { useShadowDomContext } from "./contexts/ShadowDom.context";

export type unitTypeI = "monounit" | "multiunit";

const App: React.FC<propsConfigI> = (props) => {
  const { environment, baseLanguage, shadowDom } = props;
  const { shadowRoot } = useShadowDomContext();

  const {
    fetchDefaultData,
    fetchUbicationFilterList,
    serverPath,
    loading: defaultDataLoading,
  } = useDefaultData();

  const queryParams = useQuery();
  const [language, momentLanguage] = useLanguage();

  const [loading, setLoading] = useState(true);

  (async () => {
    if (
      (!queryParams.get("language") && baseLanguage !== "en_us") ||
      (queryParams.get("language") && queryParams.get("language") !== "en_us")
    ) {
      return await import(`moment/locale/${momentLanguage ?? "es"}`);
    }
  })();

  useEffect(() => {
    if (shadowRoot) {
      if (!isProd) {
        const styles = document.querySelectorAll("style[availroom-app-styles]");
        styles.forEach((style) => {
          const clonedStyle = style.cloneNode(true) as HTMLStyleElement;
          shadowRoot.insertBefore(clonedStyle, shadowRoot.firstChild);
        });
      }
    }
  }, [shadowRoot]);

  useEffect(() => {
    (async () => {
      await fetchDefaultData(language, environment);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18next.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchUbicationFilterList(serverPath, environment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={`${styles.app}`}>
      {defaultDataLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomSpinner />
        </div>
      ) : (
        <Widget />
      )}
    </div>
  );
};

export default App;
