import React from "react";
import CustomButton from "../customButton/CustomButton";
import styles from "./Widget.module.scss";
import { useTranslation } from "react-i18next";
import LocationDropdown from "./components/locationDropdown/LocationDropdown";
import CalendarDropdown from "./components/calendarDropdown/CalendarDropdown";
import TravelersDropdown from "./components/travelersDropdown/TravelersDropdown";
import { useDefaultData } from "../../hooks/useDefaultData";

const Widget = () => {
  const { handleSubmit, type, vertical } = useDefaultData();

  const { t } = useTranslation();

  return (
    <div
      className={`${styles.specificity} ${styles.wrapper}  ${
        vertical ? `${styles.vertical}` : ""
      }`}
    >
      <div
        className={`${styles.container} ${
          vertical ? `${styles.vertical}` : ""
        }`}
      >
        {type === "multiunit" && <LocationDropdown />}
        <CalendarDropdown type={type} />
        <TravelersDropdown />
      </div>
      <div onClick={handleSubmit}>
        <CustomButton className="searchBtn">{t("search")}</CustomButton>
      </div>
    </div>
  );
};

export default Widget;
