export const styleTags: HTMLLinkElement[] = [];
export const getStyleTags = () => styleTags.map((node) => node.cloneNode(true));

export default function (linkTag: any, options: any) {
  const parent = document.querySelector("#availRoomBookingWidget");
  if (parent && parent.shadowRoot) {
    parent.shadowRoot.insertBefore(linkTag, parent.shadowRoot.firstChild);
    // const clonedLink = linkTag.cloneNode(true);
    // portalParent!.shadowRoot!.insertBefore(
    //   clonedLink,
    //   portalParent!.shadowRoot!.firstChild
    // );
  } else {
    const defaultParent = options?.target || document.head;
    defaultParent.insertBefore(linkTag, defaultParent.firstChild);
  }
  styleTags.push(linkTag);
}
